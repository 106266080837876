<template>
  <fcs-container fluid class="body overflow-auto vh-100 d-flex flex-column" id="incline-alert-screen" align-v="center">
    <Header class="mt-3" :title="$t('inclineAlert.title')"></Header>
    <div class="mt-2"></div>
    <fcs-row class="step1" v-if="activeIndex === 0">
      <fcs-col class="mx-auto" lg="12" xl="12">
        <fcs-progress-tracker
          :currentValue="$t('global.progressBar.setParam')"
          :steps="$t('global.progressBarTwoStepElements')"
          :all-steps-completed="false"
          :small-version="false"
        />
      </fcs-col>
      <fcs-col class="mx-auto" lg="8">
        <SetParameters :activeIndex='0' :cardTitle="$t('global.cardSubtitle')"></SetParameters>
      </fcs-col>
    </fcs-row>
    <fcs-container fluid v-if="activeIndex===1">
      <fcs-row class="step2">
        <fcs-col class="mx-auto" lg="12" xl="12">
          <fcs-progress-tracker
            :currentValue="$t('global.progressBar.assignVehicle')"
            :steps="$t('global.progressBarTwoStepElements')"
            :all-steps-completed="false"
            :small-version="false"
          />
        </fcs-col>
      </fcs-row>
      <AssignVehicle :cardTitle="$t('global.assignVehicles')" ></AssignVehicle>
    </fcs-container>
  </fcs-container>
</template>

<script>
import Header from '@/components/common/Header';
import SetParameters from '@/components/common/SetParameters';
import AssignVehicle from '@/components/common/AssignVehicle';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CreateInclineAlert',
  components: {
    Header,
    SetParameters,
    AssignVehicle
  },
  created () {
    this.clearWizard();
    this.assignAlertType(this.$route.params.alertType);
  },
  methods: {
    ...mapActions(['clearWizard', 'assignAlertType'])
  },
  computed: {
    ...mapState(['activeIndex'])
  }
};
</script>
